.CardOne {
    width: 100%;
    max-width: 500px;
    margin-top: 30%;
    overflow: visible;
}

.StartLetter {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.StartLetterTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.StartLetterTopTitle {
    width: 90%;
    text-align: center;
    font-size: 180%;
    color: hsl(339, 78%, 48%);
    font-weight: 600;
    margin: 10px;
}

.StartLetterTopText {
    width: 85%;
    text-align: center;
    font-size: 90%;
    color: #da1b5d;
    margin: 5px;
    font-weight: 100;
    margin-bottom: 15px;
}

.StartLetterCenter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 50%;
    min-height: 35%;
    position: relative;
}

.StartLetterBottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10%;
    position: relative;
}

.StartLetterCard {
    width: 70%;
    max-width: 400px;
    position: absolute;
    will-change: transform, opacity,  ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 90%;
    margin-top: -15%;
}

@media only screen and (max-width: 600px) {
    .StartLetterTopTitle {
        font-size: 160%;
    }

    .StartLetterTopText {
        font-size: 80%;
    }
    .StartLetterCard {
        margin-top: 0%;
}
}